@import '../resources/variables.scss';

.productCard {
  @include hover;
  width: 100%;
  display: grid;
  grid-template-columns: 90px 1fr 30px;
  grid-column-gap: 15px;
  margin-bottom: 15px;
  align-items: center;
  text-decoration: none;
  [data-image-wrapper] {
    width: 90px;
    height: 90px;
    // box-shadow: $shadowSoft;
    border: 1px solid $darkGrey;
    border-radius: 12px;
    display: grid;
    place-items: center;
    background: $softGrey;
    overflow: hidden;
    svg {
      width: 40px;
      height: 40px;
      color: $darkGrey;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  > .content {
    h3 {
      @include bebasSmall;
      margin-bottom: 2px;
      margin-top: 0;
      max-width: 200px;
      text-align: left;
    }
    > span {
      @include textSmall;
      display: block;
      margin-top: 0;
      max-width: 200px;
      text-align: left;
    }
  }
  > .arrow {
    width: 30px;
    height: 30px;
    border-radius: 30px;
    // background: $softGrey;
    border: 1px solid $darkGrey;
    display: grid;
    place-items: center center;
    svg {
      color: $darkGrey;
      height: 10px;
      width: auto;
    }
  }
}
