@import '../resources/variables.scss';

.footer {
  height: 100px;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  padding: 0 30px;
  align-items: center;
  justify-content: center;
  position: fixed;
  bottom: 0;
  // border-top-left-radius: 24px;
  // border-top-right-radius: 24px;
  border-top: 1px solid $darkGrey;
  // box-shadow: $shadowSoftReverse;
  z-index: 10;
  background: $white;
  .link {
    @include hover;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-decoration: none;
    svg {
      width: auto;
      height: 30px;
      color: $darkGrey;
    }
    span {
      @include textSmall;
      display: block;
      text-align: center;
      margin-top: 5px;
    }
    &[data-active-tab="true"] {
      svg {
        color: $text;
      }
    }
  }
}
