@import '../resources/variables.scss';

.account {
  @include screen;
  // position: absolute;
  // top: 0;
  padding-bottom: 120px;
  .top {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    align-items: center;
    [data-image-wrapper] {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
    > span {
      @include textSmall;
    }
  }
  .links {
    .link {
      @include hover;
      width: 100%;
      display: block;
      height: 60px;
      display: grid;
      grid-template-columns: 1fr 30px;
      grid-column-gap: 10px;
      align-items: center;
      text-decoration: none;
      &:not(:last-child) {
        border-bottom: 1px solid $softGrey;
      }
      > span {
        @include label;
      }
      > div {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        // background: $darkGrey;
        border: 1px solid $darkGrey;
        display: grid;
        place-items: center center;
        svg {
          color: $darkGrey;
          height: 10px;
          width: auto;
        }
      }
    }
  }
  .rewards {
    margin-bottom: 30px;
    > strong {
      @include label;
      display: block;
      margin-bottom: 15px;
    }
    .button {
      @include button;
      margin-bottom: 30px;
    }
  }
}
