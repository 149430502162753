@import '../resources/variables.scss';
.close_wrapper{
  width: 100%;
  img{
    position: relative;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .close {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 45px;
    height: 45px;
    display: grid;
    place-items: center center;
    background: white;
    border: 1px solid $text;
    border-radius: 45px;
    cursor: pointer;
    transition: 0.2s opacity;
    z-index: 10;
    &:hover {
      opacity: 0.8;
    }
    svg {
      width: 15px;
      height: 15px;
    }
  }
}

