@import '../resources/variables.scss';

.top {
  display: grid;
  grid-template-columns: 60px 1fr;
  grid-column-gap: 15px;
  margin-bottom: 30px;
  align-items: center;
  [data-image-wrapper] {
    display: flex;
    img {
      width: 60px;
      height: 60px;
      object-fit: contain;
    }
  }
  > div {
    > span {
      @include textSmall;
      display: block;
      &:first-child {
        margin-bottom: 2px;
      }
    }
  }
}
