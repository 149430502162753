@import '../resources/variables.scss';

.how {
  @include screen;
  position: absolute;
  // top: 0;
  .top {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    align-items: center;
    [data-image-wrapper] {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
    > span {
      @include textSmall;
    }
  }
}

.breadcrumb {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 17px 1fr;
  grid-column-gap: 10px;
  @include hover;
  svg {
    width: 17px;
    height: auto;
  }
  span {
    @include label;
  }
}

.cards {
  display: grid;
  place-items: center center;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin-top: 30px;
  margin-bottom: 30px;
  > div {
    width: 100%;
    background: $softGrey;
    padding: 15px;
    border-radius: 12px;
    > div {
      display: grid;
      align-items: center;
      justify-content: flex-start;
      grid-template-columns: max-content 1fr;
      grid-column-gap: 15px;
      margin-bottom: 15px;
      svg {
        height: 30px;
        width: auto;
        object-fit: contain;
        color: $accent;
      }
      span {
        @include bebasSmall;
        display: block;
      }
    }
    > span {
      @include textSmall;
    }
  }
}
