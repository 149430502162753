@import '../resources/variables.scss';

.activity {
  @include screen;
  position: absolute;
  // top: 0;
  .top {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    align-items: center;
    [data-image-wrapper] {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
    > span {
      @include textSmall;
    }
  }
}

.breadcrumb {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 17px 1fr;
  grid-column-gap: 10px;
  margin-bottom: 30px;
  @include hover;
  svg {
    width: 17px;
    height: auto;
  }
  span {
    @include label;
  }
}
