.loading {
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center center;
  img {
    width: 100px;
    height: 100px;
    object-fit: scale-down;
  }
}
