@import '../resources/variables.scss';

.signin {
  @include screen;
  position: absolute;
  // top: 0;
  img {
    width: 260px;
    height: 260px;
    object-fit: contain;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }
  p {
    margin-top: 0;
    margin-bottom: 20px;
    @include text;
    text-align: center;
    min-height: 48px;
  }
  button {
    @include button;
    margin-bottom: 20px;
  }
  .links {
    .link {
      @include hover;
      width: 100%;
      display: block;
      height: 60px;
      display: grid;
      grid-template-columns: 1fr 30px;
      grid-column-gap: 10px;
      align-items: center;
      text-decoration: none;
      &:not(:last-child) {
        border-bottom: 1px solid $softGrey;
      }
      > span {
        @include label;
      }
      > div {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        // background: $softGrey;
        border: 1px solid $darkGrey;
        display: grid;
        place-items: center center;
        svg {
          color: $darkGrey;
          height: 10px;
          width: auto;
        }
      }
    }
  }
}
