@import '../resources/variables.scss';

.cards {
  display: grid;
  place-items: center center;
  grid-template-columns: 1fr;
  grid-row-gap: 15px;
  margin-top: 30px;
  margin-bottom: 150px;
  > div {
    width: 100%;
    background: $softGrey;
    padding: 15px;
    border-radius: 12px;
    &, & > div {
      > strong {
        @include labelSmall;
        display: block;
        margin-bottom: 15px;
      }
      > span {
        @include textSmall;
        display: block;
        margin-bottom: 5px;
      }
    }
  }
  .details {
    margin-bottom: 5px;
  }
}
