
// variables

$accent: #28439c;
$accent2: #FFD05C;
$darkGrey: #BBBCC7;
$text: #1D1D1E;
$white: #fff;
$softGrey: #F6F8FB;
$accentBg: linear-gradient(135.38deg, rgba(29, 29, 30, 0.05) 2.04%, rgba(29, 29, 30, 0) 100%), #44B1F4;
$shadowSoft: 0px 4px 20px rgba(29, 29, 30, 0.1);
$popupShadow: 0px 4px 80px rgba(0, 0, 0, 0.12);
$shadowSoftReverse: 0px -4px 20px rgba(29, 29, 30, 0.1);
$whiteFade: rgba(255, 255, 255, 0.1);

// mixins

@mixin screen {
  width: 100%;
  padding: 60px 30px 80px 30px;
  &[data-has-footer="true"] {
    padding-bottom: 120px;
  }
}

@mixin noselect {
  user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  &:focus {
    outline: none;
  }
}

@mixin titleLarge {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 36px;
  line-height: 54px;
  color: $text;
}

@mixin title {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 28px;
  line-height: 42px;
  color: $text;
}

@mixin text {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}

@mixin label {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: $text;
}

@mixin textSmall {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: $text;
}

@mixin bebasHeading {
  font-family: 'Roboto Condensed', cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 31px;
  color: $text;
}

@mixin bebasSmall {
  font-family: 'Roboto Condensed', cursive;
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 25px;
  color: $text;
}

@mixin labelSmall {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 21px;
  color: $text;
}

@mixin text12 {
  font-family: 'Roboto', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  color: $text;
}

@mixin hover {
  transition: 0.2s opacity;
  cursor: pointer;
  &:hover {
    opacity: 0.8;
  }
}

@mixin button {
  @include hover;
  @include label;
  width: 100%;
  border: none;
  color: $white;
  background: $text;
  height: 50px;
  display: grid;
  place-items: center center;
  border-radius: 4px;
  text-decoration: none;
  &:focus {
    outline: none;
    text-decoration: none;
  }
  &:hover {
    text-decoration: none;
  }
}
