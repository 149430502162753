@import '../resources/variables.scss';

.confirm {
  @include screen;
  position: absolute;
  // top: 0;
  .top {
    display: grid;
    grid-template-columns: 60px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 30px;
    align-items: center;
    [data-image-wrapper] {
      display: flex;
      img {
        width: 60px;
        height: 60px;
        object-fit: contain;
      }
    }
    > span {
      @include textSmall;
    }
  }
  .rewardMain {
    display: grid;
    grid-template-columns: 150px 1fr;
    grid-column-gap: 15px;
    margin-bottom: 15px;
    [data-image-wrapper] {
      width: 150px;
      height: 150px;
      // box-shadow: $shadowSoft;
      border: 1px solid $darkGrey;
      border-radius: 12px;
      display: grid;
      place-items: center;
      background: $softGrey;
      overflow: hidden;
      svg {
        width: 80px;
        height: 80px;
        color: $darkGrey;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: scale-down;
      }
    }
    > div {
      > strong {
        @include bebasSmall;
        display: block;
        margin-bottom: 5px;
      }
      > p {
        @include textSmall;
        margin-top: 0;
        margin-bottom: 0;
      }
    }
  }
  .button {
    @include button;
    margin-bottom: 15px;
  }
  .buttonSub {
    @include  button;
    background: $softGrey;
    color: $darkGrey;
  }
  .links {
    margin-top: 30px;
    .link {
      @include hover;
      width: 100%;
      display: block;
      height: 60px;
      display: grid;
      grid-template-columns: 1fr 30px;
      grid-column-gap: 10px;
      align-items: center;
      text-decoration: none;
      &:not(:last-child) {
        border-bottom: 1px solid $softGrey;
      }
      > span {
        @include label;
      }
      > div {
        width: 30px;
        height: 30px;
        border-radius: 30px;
        // background: $softGrey;
        border: 1px solid $darkGrey;
        display: grid;
        place-items: center center;
        svg {
          color: $darkGrey;
          height: 10px;
          width: auto;
        }
      }
    }
  }
  .detailsToggle {
    @include hover;
    display: grid;
    grid-template-columns: 1fr 20px;
    border-radius: 12px;
    background: $softGrey;
    padding: 10px 20px;
    margin-bottom: 15px;
    > span {
      display: block;
      @include label;
      &:last-child {
        width: 100%;
        text-align: center;
        color: $darkGrey;
      }
    }
  }
  .details {
    @include textSmall;
    margin-bottom: 15px;
    margin-top: 0;
  }
}

.breadcrumb {
  width: 100%;
  display: grid;
  align-items: center;
  justify-content: flex-start;
  grid-template-columns: 17px 1fr;
  grid-column-gap: 10px;
  margin-bottom: 15px;
  @include hover;
  svg {
    width: 17px;
    height: auto;
  }
  span {
    @include label;
  }
}

.confirmLoading {
  @include screen;
  position: absolute;
  top: 0;
  display: grid;
  place-items: center center;
  height: calc(100% - 100px);
}

.confirmFinished {
  @include screen;
  position: absolute;
  top: 1; 
  padding-top: 0;
  display: grid;
  place-items: center center;
  // height: 100%;
  .button {
    @include button;
    margin-bottom: 15px;
  }
  .buttonSub {
    @include  button;
    background: $softGrey;
    color: $darkGrey;
  }
  h1 {
    @include  titleLarge;
    margin-top: 15px;
    margin-bottom: 15px;
  }
  [data-image-wrapper] {
    width: 150px;
    height: 150px;
    // box-shadow: $shadowSoft;
    border: 1px solid $darkGrey;
    border-radius: 12px;
    display: grid;
    place-items: center;
    background: $softGrey;
    overflow: hidden;
    margin-bottom: 15px;
    svg {
      width: 80px;
      height: 80px;
      color: $darkGrey;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: scale-down;
    }
  }
  > strong {
    @include bebasSmall;
    margin-bottom: 15px;
    display: block;
  }
  > p {
    @include textSmall;
    margin-bottom: 15px;
    margin-top: 0;
  }
  .copy {
    @include  hover;
    border: none;
    text-align: center;
    width: 100%;
    height: 50px;
    border-radius: 50px;
    background: $white;
    display: grid;
    grid-template-columns: max-content 15px;
    grid-column-gap: 5px;
    place-items: center center;
    margin-bottom: 15px;
    position: relative;
    // box-shadow: $shadowSoft;
    border: 1px solid $darkGrey;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    > span {
      @include label;
      display: inline-block;
    }
    > svg {
      width: 15px;
      height: auto;
    }
  }
}
