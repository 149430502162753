@import './resources/variables.scss';

.popup {

  @include noselect;
  width: 100%;
  height: 100vh;
  background: $white;
  // box-shadow: $popupShadow;
  border-radius: 24px;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10001;
  position: relative;

  // for  testing
  // border: 1px solid $softGrey;
  // max-width: 414px;
  // max-height: 675px;
  // ---

  &::-webkit-scrollbar {
    width: 0px;  /* Remove scrollbar space */
    background: transparent;  /* Optional: just make scrollbar invisible */
  }
}
